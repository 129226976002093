import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "components/Seo"
import PageHeader from "components/PageHeader"
import Pagination from "components/Pagination"

export default function NoticeArchive({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) {
  const posts = data.allWpNotice.nodes

  return (
    <div className="pb-16">
      <Seo title="공지사항" />
      <PageHeader title={"공지사항"} />
      <div className="pb-8">
        <div className="w-full lg:container mx-auto px-4 lg:px-8">
          {!posts.length ? (
            <div>게시글이 없습니다.</div>
          ) : (
            <div>
              {posts.map(item => {
                return (
                  <div className="border-t last:border-b border-gray-100  lg:hover:bg-gray-50">
                    <Link to={item.uri} className="py-5 px-0 lg:px-4 block">
                      <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
                        <div className="order-2 lg:order-1">
                          <h3 className="font-medium text-lg text-gray-900">
                            {item.title}
                          </h3>
                        </div>
                        <div className="order-1 lg:order-2 lg:pl-4 mb-3 lg:mb-0">
                          <p className="text-gray-500 text-sm lg:text-base">
                            {item.date}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
      <Pagination
        previousPagePath={previousPagePath}
        nextPagePath={nextPagePath}
      />
    </div>
  )
}

export const pageQuery = graphql`
  query NoticeArchive($offset: Int!, $postsPerPage: Int!) {
    allWpNotice(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        id
        date(formatString: "yyyy년 MM월 DD일", locale: "ko")
        slug
        title
        uri
      }
    }
  }
`
